import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Btn = styled.button`
  color: var(--blue);
  background-color: var(--white);
  border: 2px solid var(--blue);
  border-radius: 5px;
  box-sizing: border-box;
  line-height: 1rem;
  max-height: 4rem;
  width: 12rem;
  padding: 0;
  opacity: 0.64;
  overflow: hidden;
  min-height: calc(4rem - 2px);

  display: flex;
  align-items: center;
  justify-content: center;
  
  text-transform: uppercase;

  @media (max-width: 768px) {
    opacity: 1;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 1;
    //outline: var(--focus-color) auto 1px;
  }
`;

function StaticButton(props) {
  return (
    <Btn {...props}>
      {props.children}
    </Btn>
  );
}

StaticButton.propTypes = {
  children: PropTypes.node
};

export default StaticButton;
