import React from "react";
import PropTypes from "prop-types";
import { StaticStyledLink } from "./styledComponents";

function StaticLink(props) {
  return <StaticStyledLink {...props}>{props.children}</StaticStyledLink>;
}

StaticLink.propTypes = {
  children: PropTypes.node.isRequired
};

export default StaticLink;
