const traffic = '#0044a3';
const coal = '#1f2733';
const onyx = '#7a8799';
const coral = '#e64845';
const silver = '#d3dbe6';
const smoke = '#f5f8fc';
const snow = '#ffffff';
const transition = 'all 240ms ease';
const boxShadowModal = '0 3px 15px 3px rgba(31, 39, 51, 0.15)';

export default {
  boxShadowModal,
  traffic,
  coal,
  onyx,
  coral,
  silver,
  smoke,
  snow,
  transition
}
