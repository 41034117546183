import styled from "styled-components";

export const CoreLink = styled.span`
  font-family: GTPressuraProBold, sans-serif;
  color: var(--white);
  font-size: var(--font-size-link);
  line-height: var(--font-size-link);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  position: relative;
  /*height: var(--font-size-link);*/
  overflow: hidden;
  display: inline-block;
`;
const CoreLinkText = styled.span`
  transition: all 180ms ease-in;
  display: block;
  height: var(--font-size-link);
`;
export const LinkText = styled(CoreLinkText)`
  transform: translateY(0);
  opacity: 0.64;

  ${CoreLink}:hover & {
    transform: translateY(-100%);
  }

  ${CoreLink}.active & {
    color: var(--white);
    opacity: 1;
  }
`;
export const LinkTextHovered = styled(CoreLinkText)`
  transform: translateY(0);
  opacity: 1;

  ${CoreLink}:hover & {
    transform: translateY(-100%);
  }
`;
export const StaticStyledLink = styled(CoreLink)`
  opacity: 0.64;

  &:hover {
    opacity: 1;
  }
  
  ${CoreLink}.active & {
    color: var(--white);
    opacity: 1;
  }
`;
