import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const Btn = styled.button`
  color: ${props => (props.theme === "Light" ? "var(--blue)" : "var(--white)")};
  background-color: ${props =>
    props.theme === "Light" ? "var(--white)" : "var(--blue)"};
  border: 2px solid
    ${props =>
      props.theme === "Light"
        ? "rgba(0,25,175,0.24)"
        : "rgba(255, 255, 255, 0.4)"};
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 1rem;
  letter-spacing: 0.05em;
  height: calc(4rem - 4px);
  width: 12rem;
  min-width: 12rem;
  padding: 0;
  opacity: ${props => (props.theme === "Light" ? "1" : "0.64")};
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;  

  @media (max-width: 1224px) {
    flex: 1 0 auto;
    max-width: 14.25rem;
    margin-bottom: 2rem;
    border-color: ${props =>
      props.theme === "Light" ? "var(--blue)" : "var(--white)"};
    opacity: 1;
  }

  @media (max-width: 375px) {
    height: 3.5rem;
    font-size: 0.75rem;
    max-width: 100%;
  }

  &:hover,
  &:focus {
    cursor: pointer;
    opacity: 1;
    border-color: ${props =>
      props.theme === "Light" ? "var(--blue)" : "var(--white)"};
    outline: none;
  }
`;

const BtnSpan = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(4rem - 6px);
  width: 100%;
  max-width: 100%;
  min-width: 12rem;
  line-height: 1rem;
  text-transform: uppercase;
  transition: all 240ms ease-in-out;
  
  :after {
    content: "";
    min-height: inherit;
    font-size: 0;
  }

  &:last-child {
    color: ${props =>
      props.theme === "Light" ? "var(--white)" : "var(--blue)"};
    background-color: ${props =>
      props.theme === "Light" ? "var(--blue)" : "var(--white)"};
  }

  ${Btn}:hover & {
    cursor: pointer;
    transform: translateY(-100%);

    &:last-child {
      transform: translateY(-100%);
    }
  }

  @media (max-width: 375px) {
    min-height: 3.5rem;
  }
`;

function AnimatedButton(props) {
  return (
    <Btn {...props}>
      <BtnSpan>{props.children}</BtnSpan>
      <BtnSpan theme={props.theme}>{props.children}</BtnSpan>
    </Btn>
  );
}

AnimatedButton.propTypes = {
  theme: PropTypes.string,
  children: PropTypes.node
};

export default AnimatedButton;
