import React from "react";
import PropTypes from "prop-types";
import { GlobalStyles } from "./globalStyles";
// import SEOMetrics from "./SEOMetrics";
// import { METRIC_GOOGLE_ID, METRIC_YANDEX_ID } from "../constants";

const Layout = ({ children }) => (
  <>
    <GlobalStyles />
    {children}
    {/*<SEOMetrics googleId={METRIC_GOOGLE_ID} yandexId={METRIC_YANDEX_ID} />*/}
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
