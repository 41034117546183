import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import colors from "../../constants/colors";

const Btn = styled.button`
  display: flex;
  align-items: center;
  padding: 0;

  background: none;
  border: none;

  font-size: 14px;
  letter-spacing: 0.5px;
  text-transform: ${props => props.uppercase ? 'uppercase' : undefined };
  color: ${props => colors[props.applyColor]};
  opacity: ${props => props.opacity};

  & svg {
    margin-right: 5px;
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

function ButtonWithIcon({icon, children, color, ...buttonProps}) {
  const applyColor = buttonProps && buttonProps.disabled ? 'coal' : color;
  const opacity = buttonProps && buttonProps.disabled ? 0.4 : 1;
  return (
    <Btn
      applyColor={applyColor}
      opacity={opacity}
      {...buttonProps}
    >
      {icon && React.cloneElement(icon, { color: applyColor })}
      {children}
    </Btn>)
}

ButtonWithIcon.propTypes = {
  icon: PropTypes.node,
  children: PropTypes.node,
  color: PropTypes.string,
  uppercase: PropTypes.bool,
}

ButtonWithIcon.defaultProps = {
  uppercase: true,
}

export default ButtonWithIcon;

