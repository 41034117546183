import React from "react";
import styled from "styled-components";

const CopyrightStyled = styled.p`
  opacity: 0.4;
  color: var(--white);
`;

function Copyright() {
  return (
    <CopyrightStyled>
      &copy; {new Date().getFullYear()}, Traffic
    </CopyrightStyled>
  );
}

export default Copyright;
