import { createGlobalStyle } from "styled-components";

// language=LESS
export const GlobalStyles = createGlobalStyle`
    @font-face {
    font-family: GTPressuraPro;
    src: url(/fonts/GTPressuraPro/GT-Pressura-Pro-Regular.eot);
    src: url(/fonts/GTPressuraPro/GT-Pressura-Pro-Regular.eot?#iefix) format('embedded-opentype'),
         url(/fonts/GTPressuraPro/GT-Pressura-Pro-Regular.woff) format('woff'),
         url(/fonts/GTPressuraPro/GT-Pressura-Pro-Regular.woff2) format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: auto;
    }
    
    @font-face {
        font-family: GTPressuraProBold;
        src: url(/fonts/GTPressuraPro/GT-Pressura-Pro-Bold.eot);
        src: url(/fonts/GTPressuraPro/GT-Pressura-Pro-Bold.eot?#iefix) format('embedded-opentype'),
             url(/fonts/GTPressuraPro/GT-Pressura-Pro-Bold.woff) format('woff'),
             url(/fonts/GTPressuraPro/GT-Pressura-Pro-Bold.woff2) format('woff2');
        font-weight: 500;
        font-style: normal;
        font-display: auto;
    }
    
    @font-face {
        font-family: GTPressuraProLight;
        src: url(/fonts/GTPressuraPro/GT-Pressura-Pro-Light.eot);
        src: url(/fonts/GTPressuraPro/GT-Pressura-Pro-Light.woff) format('woff'),
             url(/fonts/GTPressuraPro/GT-Pressura-Pro-Light.eot?#iefix) format('embedded-opentype'),
             url(/fonts/GTPressuraPro/GT-Pressura-Pro-Light.woff2) format('woff2');
        font-weight: 300;
        font-style: normal;
        font-display: auto;
    }
    
    :root {
      --blue: #0044A3;
      --white: #FFFFFF;
      --black: #1f2733;
      --smoke: #f5f6fc;
      
      --font-size-global: calc(12px + (16 - 12) * ((100vw - 375px) / (1440 - 375)));
      
      /* scale for desktop */

      --font-size-h1: 4rem;      // 64px 
      --font-size-h2: 6rem;      // 96px
      --font-size-h3: 3rem;      // 48px
      --font-size-h4: 1.375rem;  // 22px
      --font-size-text: 1rem;    // 16px
      --font-size-link: 0.75rem; // 12px
      --font-size-button: 0.875rem; // 14px
      
      
      font-size: var(--font-size-global);
      
      --line-height-h1: 4.5rem;
      --line-height-h3: 3.5rem;
      --line-heigth-h4: 2rem;
    }
    
    * {
      box-sizing: border-box;
    }
    
    html {
      font-family: GTPressuraPro, sans-serif;
      text-size-adjust: 100%;
    }
    body {
        min-width: 1024px; // Нужно для индекса цен, так как нет адаптива
        line-height: 1.625rem;
        color: #1f2733;
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    
    @media (min-width: 1440px) {
      :root{
        font-size: 16px;
      }
    }
    
    @media (min-width: 376px) and (max-width: 768px) {
      :root {
        --font-size-global: calc(12px + (16 - 12) * ((100vw - 375px) / (768 - 375)));
        --font-size-h1: 4rem;       // 64px  
        --font-size-h2: 4rem;       // 64px
        --font-size-h3: 1.75rem;    // 28px
        --font-size-h4: 1.25rem;    // 20px
        --font-size-button: 0.75rem; // 12px
         
         --line-height-h3: 2.375rem;
        --line-height-h4: 1.875rem;
        font-size: var(--font-size-global)
      }
    }
    
    @media (max-width: 375px) {
      :root {
        --font-size-global: calc(12px + (16 - 12) * ((100vw - 0px) / (375 - 0)));
        --font-size-h1: 2.5rem;    // 40px
        --font-size-h2: 2.5rem;    // 40px
        --font-size-h3: 1.25rem;   // 20px
        --font-size-h4: 1rem;      // 16px 
        --font-size-text: 0.75rem; // 12px
        --font-size-link: 1.5rem;  // 24px
        --font-size-button: 0.75rem; // 12px
        
        --line-height-h1: 3rem;
        --line-height-h3: 1.875rem;
        --line-heigth-h4: 1.625rem;
        
        font-size: var(--font-size-global)
      }
    }
 
  h1 {
    font-size: var(--font-size-h1);
    font-family: GTPressuraProBold, sans-serif;
    font-weight: inherit;
    line-height: var(--line-height-h1);
  }
  
  h2 {
    font-size: var(--font-size-h2);
    font-family: GTPressuraProBold, sans-serif;
    font-weight: inherit;
  }
  h3 {
    font-size: 48px;
    font-size: var(--font-size-h3);
    font-family: GTPressuraProBold, sans-serif;
    font-weight: inherit;
    line-height: var(--line-height-h3);
  }
  h4 {
    font-size: var(--font-size-h4);
    font-family: GTPressuraProBold, sans-serif;
    font-weight: inherit;
    line-height: var(--line-heigth-h4);
  }

  p {
    font-size: var(--font-size-text);
    font-family: GTPressuraPro, sans-serif;
    font-weight: 400;
  }

  a {
    text-decoration: none;
    color: var(--white);
  }

  button {
    font-size: var(--font-size-button);
    font-family: GTPressuraProBold, sans-serif;
    font-weight: inherit;
  }
  
  svg path {
  // TODO: вернуть, если надо
  // fill: var(--blue);
  }
  
  /* Apexcharts styles */
  .apexcharts-tooltip {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100px;
    min-height: 55px;
    transform: translateY(-80%);
    text-align: center;
    border-radius: 0!important;
    border: 2px solid var(--blue)!important;
  }
  
    .apexcharts-tooltip.light .apexcharts-tooltip-title {
      padding: 0;
      margin: 0;
      font-size: 12px!important;
      font-family: GTPressuraPro, sans-serif!important;
      color: #7a7f99;
      background:var(--white)!important;
      border-bottom: none!important;
    }

    .apexcharts-tooltip-series-group.active, .apexcharts-tooltip-series-group:last-child {
      padding-bottom: 0;
      line-height: 1.39;
    }

    .apexcharts-tooltip-series-group.active {
      text-align: center;
      justify-content: center;
    }
    .apexcharts-tooltip.light .apexcharts-tooltip-text {
      font-family: GTPressuraProBold, sans-serif;
      font-size: 18px!important;
      text-align: center;
      margin: 0;
      padding: 0;
    }
    .apexcharts-tooltip.light .apexcharts-tooltip-text-value {
      margin-left: 0;
    }

    .apexcharts-canvas.zoomable .hovering-zoom {
      cursor: pointer!important;
    }
    
    .apexcharts-xaxistooltip-bottom,
    .apexcharts-toolbar {
      display: none!important;
    }
    
    .apexcharts-marker {
      fill: var(--blue);
      stroke-width: 3px;
      stroke-opacity: 1;
    }

    .apexcharts-xcrosshairs.active, .apexcharts-ycrosshairs.active {
      stroke: var(--blue);
      stroke-dasharray: none;
    }
    
    .svg_select_points_l.svg_select_points, .svg_select_points_r.svg_select_points {
      fill: var(--white);
      stroke: #d3dbe6;
    }
    
   /* Container and media */
  .container {
    max-width: 76.5rem;
    margin-left: auto;
    margin-right: auto;
    
    @media (max-width: 1439px) {
      max-width: 85vw;
    }
    
    @media (max-width: 768px) {
      max-width: calc(100vw - 4.5rem);
    }
    
    
    @media (max-width: 375px) {
      max-width: calc(100vw - 2px - 2.5rem)
    }
  }
`;
